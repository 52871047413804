import React from "react"
import Header from "./Header";
import {useEffect, useState} from "react";
import Footer from "./Footer";
import Loader from "./Loader";
import NoInternet from "./NoInternet";

const Skills = () => {
    const [skills, setSkills] = useState([ {} ]);
    const [isLoaded, setIsLoaded] = useState(false); 
    const [skillsCategory, setSkillsCategory] = useState([]); 
    const [skillsCategoryImages, setSkillsCategoryImages] = useState([]); 

    const personalizeSetSkillsCategory = (newElements) =>{
        let found = skillsCategory.map((x) => {
            let out = false;
            if(JSON.stringify(x) === JSON.stringify(newElements)){
                out = true;
            }
            return out;
        });
        found = found.includes(true)
        if(!found){
            let newArray = skillsCategory;
            newArray.push(newElements);
            setSkillsCategory(newArray);
        }
    }


    const personalizeSetSkillsCategoryImages = (newElements) =>{
        let found = skillsCategoryImages.map((x) => {
            let out = false;
            if(JSON.stringify(x) === JSON.stringify(newElements)){
                out = true;
            }
            return out;
        });
        found = found.includes(true)
        if(!found){
            let newArray = skillsCategoryImages;
            newArray.push(newElements);
            setSkillsCategoryImages(newArray);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.daniel-monteiro.fr/api/category-skills");
                const jsonData = await response.json();
                setSkills(jsonData);
                processSkills(jsonData); 
            } catch (error) {
                console.error("Error fetching data:", error);
                alert("Veuillez vérifier la connexion, car il semble qu'il n'y ait pas de connexion disponible actuellement.")
            }
        };
    
        const processSkills = async (skillsData) => {
            for (let i = 0; i < skillsData.length; i++) {
                try {
                    const response = await fetch(`https://api.daniel-monteiro.fr/api/skills/${skillsData[i].categoryText}/names`);
                    const jsonData = await response.json();
                    personalizeSetSkillsCategory(jsonData);
                    const responseImages = await fetch(`https://api.daniel-monteiro.fr/api/skills/${skillsData[i].categoryText}/images`);
                    const jsonDataImages = await responseImages.json();
                    personalizeSetSkillsCategoryImages(jsonDataImages);
                    document.querySelector(".loader").classList.add("fade-out");
                    setTimeout(() => {
                        setIsLoaded(true);
                    }, 500);
                } catch (error) {
                    console.error("Error fetching data:", error);
                    alert("Veuillez vérifier la connexion, car il semble qu'il n'y ait pas de connexion disponible actuellement.")
                }
            }
        };
    
        fetchData();
    }, []);
    console.log("skills", skills);
    console.log("skillsCategory", skillsCategory);
    console.log("skillsCategoryImages" ,skillsCategoryImages);
    if(!isLoaded){    
        return(
            <React.Fragment>
                <Loader/>
            </React.Fragment>
        )
    }
    else{
        return (
            <div>
            <div className="fade-in m-20">
            <section id="skills" className="mb-10">
                {skills.map((element, index) => (
                    <div key={element._id}>
                        <h1 className="font-sans mb-4 text-center p-5 text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-8xl 2xl:text-4xl">{element.categoryText.replaceAll("_", " ")} :</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                            {skillsCategory[index] && skillsCategory[index].map((el, ind) => (
                                <div className="bg-white p-10 rounded-md shadow-md" key={el}>
                                    <p className="text-center h-1 p-5">{el}</p>
                                    <img src={ `img/languages/${skillsCategoryImages[index][ind]}` } alt={el} height={"20px"} className="mt-8"/> 
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </section>
            </div>
            <Footer />
        </div>
        )
    }
}

export default Skills;