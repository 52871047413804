import React from "react";
import ReactDOM from 'react-dom';

import me from "./assets/me.jpeg"
import Header from "./Header";
import {useEffect, useState} from "react";
import Footer from "./Footer";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";



const Home = () => {
    const navigate = useNavigate();
    const [aboutMe, setAboutMe] = useState([ {} ]);
    const [isLoaded, setIsLoaded] = useState(false); 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.daniel-monteiro.fr/api/about-me");
                const jsonData = await response.json();
                setAboutMe(jsonData);
                document.querySelector(".loader").classList.add("fade-out");
                setTimeout(() => {
                    setIsLoaded(true);
                }, 500);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    let arrayBulletPoints = aboutMe[0].bulletpoints!=undefined ? aboutMe[0].bulletpoints.split(";") : [];
    if(!isLoaded){    
        return(
            <React.Fragment>
                <Loader/>
                <Footer/>
            </React.Fragment>
        )
    }
    else{
        return(
            <React.Fragment>
                <div className="fade-in">
                    <header className="relative text-center bg-amber-700 text-white h-lvh">
                            {/* Video Background */}
                            <video className="absolute inset-0 w-full h-full object-cover" autoPlay muted loop>
                                <source src="video/video.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            
                            {/* Content */}
                            <div className="relative z-10 flex flex-col items-center justify-center h-lvh">
                                <img src={me} alt="moi" className="rounded-full w-64 h-64 mx-auto mb-4 border-4 border-white" />
                                <div className="mb-4">
                                    <img src="./img/logo/logo.png" className="mx-auto" width="400" alt="logo" />
                                </div>
                                <p className="font-sans text-2xl dark:text-slate-200">{aboutMe[0].calloutText}</p>
                                <div class="border-t border-gray-300 my-4 p-1 w-64"></div>
                                <div className="social-buttons">
                                    <a href="https://www.linkedin.com/in/daniel-monteiro-751737237/" className="social-button social-button--linkedin" aria-label="LinkedIn" target="_blank">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                    <a href="https://github.com/DanielDSM23" className="social-button social-button--github" aria-label="GitHub" target="_blank">
                                        <i className="fab fa-github"></i>
                                    </a>
                                </div>
                                <h1 id="gotoAbout" title="À Propos" className="p-6 m-10" onClick={function scrollToAbout() {
                                    var aboutSection = document.getElementById('about');
                                    aboutSection.scrollIntoView({ behavior: 'smooth' });
                                }}><img src="img/toDown.png" style={{height: "14px"}} /></h1>
                            </div>
                    </header>
                    <section id="about" className="container mx-auto px-4 mb-10 animate-fade text-2xl font-sans p-20">
                    <div className="relative z-10 flex flex-col items-center justify-center h-lvh">
                        <h1 className="text-4xl font-bold text-center text-black mb-10">À Propos de moi :</h1>
                        <p className="mb-6 leading-8 text-center">{aboutMe[0].title}</p>
                        <ul className="list-disc list-inside mb-6">
                            {arrayBulletPoints.map((element, index) => (
                            <li key={index} className="mb-2">{element}</li>
                            ))}
                        </ul>
                    
                        <div className="flex justify-center">
                            <a onClick={()=>navigate("/projets")} className="bg-gray-800 opacity-75 text-white font-bold py-2 px-4 rounded mr-4 hover:opacity-100 transition-all">Voir mes projets</a>
                            <a href="/files/CV.pdf" className="bg-gray-800 text-white opacity-75 font-bold py-2 px-4 rounded hover:opacity-100 transition-all">Voir mon CV</a>
                        </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </React.Fragment>

            )
    }
}

export default Home;