import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const menu = (path = null) => {
        const menu = document.querySelector(".menu-icon");
        const navBar = document.querySelector("nav");
        const contentMenu = document.querySelector("#mobile-menu");
        if(!menu.classList.contains("open")){ //is closed 
            menu.classList.add("open");
            navBar.classList.add("fade-in-top-menu");
            contentMenu.classList.add("fade-in");
            contentMenu.style.display = "flex"
            setTimeout(() => {
                navBar.style.height = "100vh"
                navBar.classList.remove("fade-in-top-menu");
                contentMenu.classList.remove("fade-in");
            }, 500);
        }
        else{ //is opened
            contentMenu.classList.add("fade-out");
            setTimeout(() => {
                navBar.classList.add("fade-out-top-menu");
                setTimeout(() => {
                    navBar.style.height = "72px";
                    navBar.classList.remove("fade-out-top-menu");
                }, 490);
            }, 200);
            setTimeout(() => {
                contentMenu.style.display = "none";
                contentMenu.classList.remove("fade-out");
            }, 500);
            menu.classList.remove("open");
        }
        if(path != null){
            navigate(path);
        }
    }
    return (
    <nav className="bg-gray-800 p-4 sticky inset-0 z-20">
    <div className="max-w-7xl mx-auto flex justify-between items-center">
        <img src="./img/logo/logo1.png" alt="Daniel MONTEIRO" className="w-10"/>
        <div className="menu-icon md:hidden" onClick={() => menu() }><span></span></div>
        <div class="hidden md:block">
            <a onClick={() => navigate("/")} className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">À propos de moi</a>
            <a onClick={() => navigate("/competences")} className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Compétences</a>
            <a onClick={() => navigate("/projets")} className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Projets</a>
        </div>
    </div>
    <div id="mobile-menu" className="hidden md:hidden relative z-10 flex-col items-center justify-center h-lvh text-4xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-8xl 2xl:text-10xl">
    <a onClick={() => menu("/")} className="block text-white px-3 py-10 rounded-md font-medium opacity-50 hover:opacity-100 font-sans" style={{transition: "opacity 500ms"}}>À propos de moi</a>
        <a onClick={() => menu("/competences")} className="block text-white px-3 py-10 rounded-md font-medium opacity-50 hover:opacity-100 font-sans" style={{transition: "opacity 500ms"}}>Compétences</a>
        <a onClick={() => menu("/projets")} className="block text-white px-3 py-10 rounded-md font-medium opacity-50 hover:opacity-100 font-sans" style={{transition: "opacity 500ms"}}>Projets</a>
    </div>
  </nav>)
}


export default Header;