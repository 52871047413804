import * as React from "react";
const SVGComponent = (props) => (
  <svg
    x={0}
    y={0}
    filtersec="colorsb7128171728"
    className="image-svg-svg primary"
    style={{
      overflow: "visible",
      width: 100,
      height: 100,
    }}
    {...props}
  >
    <svg
      viewBox="0 0 287.7720031738281 317.8940124511719"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" pointerEvents="none" d="M0 0h287.772v317.894H0z" />
      <linearGradient
        id="a788d7653-901c-4d47-b585-7bef1a647426"
        gradientUnits="userSpaceOnUse"
        x1={664.042}
        y1={205.491}
        x2={742.073}
        y2={205.491}
      >
        <stop offset={0} stopColor="#fff33b" />
        <stop offset={0.396} stopColor="#f7ae3b" />
        <stop offset={1} stopColor="#e93e3a" />
      </linearGradient>
      <linearGradient
        id="b801439e3-03f8-4699-9be2-31f7c8146ada"
        gradientUnits="userSpaceOnUse"
        x1={664.042}
        y1={188.312}
        x2={679.314}
        y2={188.312}
      >
        <stop offset={0} stopColor="#ee2a7b" />
        <stop offset={0.234} stopColor="#bb2198" />
        <stop offset={0.766} stopColor="#3a0adf" />
        <stop offset={1} stopColor="#00f" />
      </linearGradient>
      <linearGradient
        id="c7577ab6a-53b0-4670-a2ae-3b5d297efa3b"
        gradientUnits="userSpaceOnUse"
        x1={664.042}
        y1={171.134}
        x2={742.073}
        y2={171.134}
      >
        <stop offset={0} stopColor="#0ff" />
        <stop offset={0.219} stopColor="#0cf" />
        <stop offset={0.717} stopColor="#004bff" />
        <stop offset={1} stopColor="#00f" />
      </linearGradient>
      <linearGradient
        id="da0ad0b64-80fe-4ca2-9952-c24257cee40c"
        gradientUnits="userSpaceOnUse"
        x1={724.753}
        y1={153.955}
        x2={742.073}
        y2={153.955}
      >
        <stop offset={0} stopColor="#fff33b" />
        <stop offset={0.396} stopColor="#f7ae3b" />
        <stop offset={1} stopColor="#e93e3a" />
      </linearGradient>
      <path
        d="M282.203 311.941v-92.71H85.508v-15.033h133.74l62.955-71.098V4.059h-85.506v107.43H62.331L6.267 183.213v57.004l56.064 71.724z"
        fill="#FFF"
      />
      <path
        d="M664 195.7h78.1v19.6h-62.8z"
        fill="url(#a788d7653-901c-4d47-b585-7bef1a647426)"
        transform="matrix(3.13207 0 0 3.13207 -2057.769 -378.054)"
      />
      <path
        d="M679.3 161.3v54L664 195.7v-14.8z"
        fill="url(#b801439e3-03f8-4699-9be2-31f7c8146ada)"
        transform="matrix(3.13207 0 0 3.13207 -2057.769 -378.054)"
      />
      <path
        d="M664 180.9h60.8l8.6-9.8 8.7-9.8h-62.8z"
        fill="url(#c7577ab6a-53b0-4670-a2ae-3b5d297efa3b)"
        transform="matrix(3.13207 0 0 3.13207 -2057.769 -378.054)"
      />
      <path
        d="M724.8 180.9l17.3-19.6V127h-17.3z"
        fill="url(#da0ad0b64-80fe-4ca2-9952-c24257cee40c)"
        transform="matrix(3.13207 0 0 3.13207 -2057.769 -378.054)"
      />
    </svg>
  </svg>
);
export default SVGComponent;
