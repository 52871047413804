import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Home from './Home';
import Skills from './Skills';
import Projects from './Projects';
import './index.css'; 
import Footer from './Footer';
import Loader from './Loader';
import Header from './Header';


function App() {
  return (
    <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={ <Home/> } />
          <Route path="/competences" element={ <Skills/> } />
          <Route path="/projets" element={ <Projects/> } />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
