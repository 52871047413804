import React from "react";
import Header from "./Header";
import {useEffect, useState} from "react";
import Footer from "./Footer";
import Loader from "./Loader";


export default function Projects() {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(()=>{
        document.querySelector(".loader").classList.add("fade-out");
        setTimeout(() => {
            setIsLoaded(true);
        }, 500);
    }, [])

    if(!isLoaded){
        return (
            <React.Fragment>
                <Loader/>
                <Footer/>
            </React.Fragment>
        )
    }
    else{
        return(
            <React.Fragment>
            <div className="fade-in">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 m-20">
              {/* Gestion des commandes dans les restaurants */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/nodejs.svg" alt="Node.js" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/jquery.svg" alt="JQuery" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Gestion des commandes dans les restaurants</h3>
                <p className="text-sm mb-2">Node.js, JQuery • Personnel • 2024</p>
                <p className="mb-4">Conception d'un système client-serveur en Node.js, JQuery pour la gestion des commandes dans les restaurants. (Disponible sur GitHub)</p>
                <a href="https://github.com/DanielDSM23/mcdo_order" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block" />
                </a>
              </div>
              {/* Rambolimanager */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/cpp.svg" alt="C++" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/qt.png" alt="Qt" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Rambolimanager</h3>
                <p className="text-sm mb-2">Qt (C++) • Personnel • 2023</p>
                <p className="mb-4">Application permettant de modifier la base de données du site web Rambolitalents sans nécessiter de prérequis MySQL.</p>
                <a href="https://github.com/DanielDSM23/Rambolimanager" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block" />
                </a>
              </div>
              {/* Rambolichecker */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/swift.png" alt="Swift" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Rambolichecker</h3>
                <p className="text-sm mb-2">SwiftUi • Personnel • 2023</p>
                <p className="mb-4">Effectue la vérification des billets des produits sélectionnés en se basant sur une base de données. (Disponible sur GitHub)</p>
                <a href="https://github.com/DanielDSM23/Rambolichecker" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block" />
                </a>
              </div>
              {/* Tic-Tac-Toe */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/swift.png" alt="Swift" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Tic-Tac-Toe</h3>
                <p className="text-sm mb-2">SwiftUi • Bachelor • 2023</p>
                <p className="mb-4">Application du jeu du Morpion développée en SwiftUI. (Disponible sur GitHub)</p>
                <a href="https://github.com/DanielDSM23/Tic-Tac-Toe_IOS" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block" />
                </a>
              </div>
              {/* Site internet (association artistique) */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/php.png" alt="PHP" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/jquery.svg" alt="JQuery" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/html.svg" alt="HTML" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/css.svg" alt="CSS" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/javascript.svg" alt="JavaScript" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/qt.png" alt="Qt" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Site internet (association artistique)</h3>
                <p className="text-sm mb-2">PHP, JQuery, PhpMyAdmin • Personnel • 2023</p>
                <p className="mb-4">Création de la base de données (Comptes, Produits, Paniers, Commandes...). Création de pages web en HTML, CSS, PHP (communiquant avec la base de données) et JavaScript (utilisant la bibliothèque jQuery)</p>
                <a href="https://rambolitalents.daniel-monteiro.fr/" target="_blank">
                  <img src="./img/web.png" alt="Website" className="w-8 h-8 inline-block margin mr-1" />
                </a>
                <a href="https://membre.rambolitalents.daniel-monteiro.fr/" target="_blank">
                  <img src="./img/web.png" alt="Website" className="w-8 h-8 inline-block" />
                </a>
              </div>
              {/* Projet Domo-Secours */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/cpp.svg" alt="C++" className="w-8 h-8 inline-block mb-2" />
                <img src="./img/languages/arduino.png" alt="Arduino" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Projet Domo-Secours</h3>
                <p className="text-sm mb-2">Qt (C++), Arduino (C++) • BTS • 2022</p>
                <p className="mb-4">Conception d'un système innovant de détection de chutes à l'aide d'une montre (prototype) programmée en C++ avec l'environnement Arduino. Ce système assure une communication efficace dans le réseau local via le protocole MQTT et s'intègre parfaitement avec la base de données</p>
                <a href="https://github.com/DanielDSM23/Projet-Domo-Secours-BTS-SNIR-Application" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block mr-1" />
                </a>
                <a href="https://github.com/DanielDSM23/Projet-Domo-Secours-BTS-SNIR-Montre" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block" />
                </a>
              </div>
              {/* Jeu */}
              <div className="bg-white p-6 rounded-md shadow-md">
                <img src="./img/languages/python.svg" alt="Python" className="w-8 h-8 inline-block mb-2" />
                <h3 className="text-lg font-bold mb-2">Jeu</h3>
                <p className="text-sm mb-2">Python (Module Pygame) • Terminale • 2020</p>
                <p className="mb-4">Application du jeu du Morpion développée en SwiftUI. (Disponible sur GitHub)</p>
                <a href="https://github.com/DanielDSM23/dodgeCar-Projet-ISN" target="_blank">
                  <img src="./img/github.png" alt="GitHub Repository" className="w-6 h-6 inline-block" />
                </a>
              </div>
            </div> 
          </div>
          <Footer />
          </React.Fragment>
        ); 
    }
}